<template>
  <base-page>
    <router-view />
  </base-page>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'InstancePage',

  meta () {
    return {
      titleTemplate: title => `${title} - Instance - Comité | AGORA`
    }
  },

  preFetch ({ store, currentRoute, previousRoute, redirect, ssrContext }) {
    store.dispatch('sujets/fetchSujets', { rowsPerPage: 0, statut: 'A_PLANIFIER', sortBy: 'label' })

    if (currentRoute.params.id) {
      return store.dispatch('instances/getInstance', currentRoute.params.id)
    } else {
      return store.dispatch('instances/fetchFormFields')
    }
  },

  beforeDestroy () {
    this.$store.commit('instances/setCurrentInstance', null)
  },

  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      instance: 'instances/getCurrentInstance'
    })
  },

  mounted () {
    this.$store.commit('pages/setBreadcrumbs', [
      { label: this.$t('global.home'), to: { name: 'home' }, icon: 'home' },
      { label: this.$t('instances.instances_list'), to: { name: 'instances-list' } },
      {}
    ])

    if (this.instance) {
      this.$store.dispatch('instances/fetchFormFields', this.instance.statut === 'CLOTURE' ? { droits_title: 'CLOTURE' } : {})
      this.$store.commit('pages/setPageTitle', this.instance['label'])
    }
  }
}
</script>
